import React, { useState } from 'react';
import {GetTempJWT,UploadFileAPI } from '../api.min'; 
import { useGlobalState } from './StateContext';

const FileUpload = () => {
  const {isCN, USERID,handleCurrentDocId,handleCurrentFileName } = useGlobalState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const validateFileType= (file)=> {
    const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

    // Check if the file's MIME type is allowed
    if (!allowedTypes.includes(file.type)) {
        return false;
    }

    // Check file extension (optional)
    const fileNameParts = file.name.split('.');
    const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();
    if (fileExtension !== 'pdf' && fileExtension !== 'doc' && fileExtension !== 'docx') {
        return false;
    }

    return true;
}

  const handleUpload = async () => {
    if (selectedFile) {
      if (!validateFileType(selectedFile)){
        alert("wrong file type");

        return;
      }
      if (!checkFileSize(selectedFile))
      return;

      handleCurrentFileName(selectedFile);
      setLoading(true);

      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('userid', USERID);

      try{      
          const jwt = await GetTempJWT(USERID);
          const response = await UploadFileAPI(jwt,formData);   
          if (response != 'FAILED' && response != '')
          {
             handleCurrentDocId(response);
             handleCurrentFileName(selectedFile.name);
             alert(isCN?'文件上传成功':'File uploaded successfully');
           }
           else{
              alert(isCN?'文件上传出错':'Error uploading file:');
           }
          setLoading(false);
        }

        catch(error){
          console.error(isCN?'文件上传出错':'Error uploading file:', error);
          setLoading(false);
        }
    } else {
      alert(isCN?'请选择文件':'Please select a file');
    }
  };

  const checkFileSize = (file)=> {
      const fileSizeInBytes = file.size;
      const maxSizeInBytes = 5 * 1024 * 1024; // 10 MB (adjust as needed)
      
      if (fileSizeInBytes > maxSizeInBytes) {
        alert(isCN?'文件不能超过5MB':'File size exceeds the maximum allowed size (5MB).');
        return false;
      }
      return true;

  }

  return (
    <div style={{fontSize:20}}>
      <p>&nbsp;{isCN?'请点击下面的Choose File':'Please click "Choose File" below'}</p>
      <input type="file" onChange={handleFileChange} style={{fontSize:18,width:'100%'}}/>
      <br/>    <br/>   
      &nbsp;&nbsp;<button onClick={handleUpload} disabled={loading} style={{fontSize:20}}>{loading ? isCN?'正在上传...':'Uploading...' : isCN?'上传':'Upload'}</button>

    </div>
  );
};

export default FileUpload;
