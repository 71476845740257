import React,{useState,useEffect, useRef} from "react";
import './Chat.css'; 
import { GetTempJWT,GetChatCompletionsAPI,GetUserAccountAPI,GetTipsAndNewsAPI} from '../api.min'; 
import { useGlobalState } from './StateContext';
import {validateEmail} from '../utility'
import sendicon from '../send.png'
import MovingDivs from "./movingDivs";

const Chat = () => {
   const { isCN, USERID,todayTipCN,todayTipEN,todayNewsCN,todayNewsEN,handleTipsAndNews,handleLogout,parseCookie,TotalAIToken,gptModel,handleGetTotalAIToken,showWelcome,handleWelcome} = useGlobalState();
    //#region chat messages, retrieve and post

    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef(null);
    var messages =  JSON.parse(sessionStorage.getItem('messages')) || [{role:"assistant",content:"Welcome to GPT Mate, powered by OpenAI."}];
    const [processing, setProcessing] = useState(false);

    const RetrieveUserAccount = async()=>{
      const jwt = await GetTempJWT(USERID);
      const totalAIToken = await GetUserAccountAPI(jwt,USERID);
      handleGetTotalAIToken(totalAIToken);
    }

    const [messageList, setMessageList] = useState(messages);

    const callAzureOpenAI = async () => {
    
        if (TotalAIToken<=0){
          alert(isCN?'您的AI豆已经用完。请购买充值码进行充值。\n\n也可以分享GPT Mate以获得赠送豆, 分享方法在“我的帐户”里。':'Your AI Tokens have been exhausted. Please acquire a recharge code to top up. You can also share GPT Mate to receive bonus AI Tokens. The sharing method is in "My Account".');    
        }
        else{   
          if (gptModel === 'GPT-4' && TotalAIToken<15)   
          {    
            alert(isCN?'您的AI豆余额不够使用GPT-4, 请购买充值码进行充值。\n\n也可以分享GPT Mate以获得赠送豆, 分享方法在“我的帐户”里。':'Your AI Token balance is not sufficient to use GPT-4, please acquire a recharge code to top up. You can also share GPT Mate to receive bonus AI Tokens. The sharing method is in "My Account".');   
          }
          else{
            setProcessing(true);
            try {             
              //add prompt into temp message list
              messages.push({ role: "user", content: inputValue });
              setInputValue('');

              setMessageList(messages);
              if (!validateEmail(USERID)){
                handleLogout();
              }
              const jwt = await GetTempJWT(USERID);
              const result = await GetChatCompletionsAPI(jwt,USERID, gptModel,messages);
              messages.push({ role: "assistant", content: result });
              sessionStorage.setItem('messages', JSON.stringify(messages));
      
              setProcessing(false);  
              RetrieveUserAccount();

            } catch (error) {
                setProcessing(false);
            }
          }
        }
      };

    const handleSubmit =  (e) => {
      e.preventDefault();       
      setInputValue(e.target.value);
      
      if (inputValue.length === 0){
        alert(isCN?'请输入提示':'Please input the prompt');
      }
      else{
        callAzureOpenAI();
      }
      
      adjustInputHeight();
    };

    const handleInputChange = (event) => {
      setInputValue(event.target.value);
      adjustInputHeight();
      handleWelcome(false);
    };
  
    const adjustInputHeight = () => {
      const input = inputRef.current;
      if (input) {
        input.style.height = 'auto';
        input.style.height = `${input.scrollHeight}px`;
      }
    };
    
    //#endregion

    const RefreshTipsAandNews = async ()=>{

      // sessionStorage.clear();
      // handleWelcome(true);

      //refresh Tips and News
      const jwt = await GetTempJWT(USERID);
      const result = await GetTipsAndNewsAPI(jwt,USERID);
      const tipList = result.filter(item=>item.category ==='TIP');
      const newsList = result.filter(item=>item.category ==='NEWS');

      const latestTIP = tipList.reduce((latest,current)=>{
          const latestDate = new Date(latest.createdDateTime);
          const currentDate = new Date(current.createdDateTime);
          return latestDate > currentDate ? latest : current;
      });
      const latestNews = newsList.reduce((latest,current)=>{
          const latestDate = new Date(latest.createdDateTime);
          const currentDate = new Date(current.createdDateTime);
          return latestDate > currentDate ? latest : current;
      });


      handleTipsAndNews(latestTIP.contentCN,latestTIP.contentEN, latestNews.contentCN,latestNews.contentEN);
  };

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value)
      .then(() => {
       alert(isCN?'已拷贝到剪贴板':'Copied to clipboard');    
      })
      .catch(() => {
        //console.error('Error copying text: ', error);
      });
  };

  const  handleSpeech = (text) =>{
    if ('speechSynthesis' in window) {
      const speech = new SpeechSynthesisUtterance(text);
      window.speechSynthesis.speak(speech);
    } else {
      console.error('SpeechSynthesis API not supported');
      // Optionally, you can provide a fallback for browsers that do not support SpeechSynthesis API
    }
  };

    useEffect(() => {
      // Scroll to bottom of chat when new message is added
      const chatWindow = document.getElementById('output-window');
      chatWindow.scrollTop = chatWindow.scrollHeight;
      adjustInputHeight();
    }, [messages]);

    useEffect(() => {
      parseCookie();
      RetrieveUserAccount();
      RefreshTipsAandNews();
    }, []);

    return (

        <div id="output-window" style={{ width:'100%', height: '90vh', overflowY: 'auto', bottom: 15,marginLeft:20,marginBottom:100}}>
            <div className="container">
                <div className="fixed-top">
                      <div style={{position:'fixed',top:1,right:10,fontSize:12,backgroundColor:'#0EE90',maxHeight:40}}>
                        <p>{TotalAIToken}</p>
                      </div>

                      <div style={{position:'fixed',top:15,right:10,fontSize:12,backgroundColor:'#0EE90',maxHeight:40}}>
                        <p>{gptModel}</p>
                      </div>   
                </div>
              
                <div className="scrollable-content">
                   {messageList.map((message, index) => (<p style={{fontSize:18}} key={index}>{message.role === 'user'?<strong>You</strong>:<strong>ChatGPT</strong>}<br/>
                   <div style={{ whiteSpace: 'pre-line' }}>{message.content} 
                   &nbsp; 
                   <img src="copy_icon.png" width={10}  onClick={()=> handleCopy(message.content)}/>
                   &nbsp;  &nbsp;
                   <img src="speaker.png" width={15}  onClick={()=> handleSpeech(message.content)}/>
                   
                   </div><br/></p>))}
                  {processing && <img src="chatloading.gif" width={160}/>}
                </div>
              
                {showWelcome &&
                    <div style={{backgroundColor: '#f0f0f0', borderRadius:40,position:'relative',left:20,maxWidth:260,minHeight:230}}>
                        
                      <ul>
                          <img src='chatgptlogo.png' width={30} style={{position:'relative',left:72,top:18}}></img>
                          <br/>
                        <div style={{position:"relative",right:20}}>
                          <h5>{isCN?'今天小贴士:':'Today\'s tips:'}</h5>
                          <h5>{isCN?todayTipCN:todayTipEN}</h5>
                          <p style={{textAlign:'center'}}>{isCN?'有什么可以帮到您吗?':'How can I help you today?'}</p>
                        </div>

                      </ul>
                    </div>
                }
                {showWelcome && <div> <MovingDivs text={isCN?todayNewsCN:todayNewsEN}/></div>}

                <div className="fixed-bottom">
                    <textarea maxLength={2000} ref={inputRef} value={inputValue} onChange={handleInputChange} placeholder={isCN?' 请输入信息':' Message'}
                        style={{width:'66%', resize: 'none', borderRadius: '15px', outline: 'none', minHeight: '40px',maxHeight: '200px',maxWidth:'1200px' ,overflow:'auto',fontSize:18 }}
                        rows={1} />
                    <button onClick={handleSubmit} type="submit" style={{position:'fixed',bottom:15, backgroundImage:  `url(${sendicon})`,backgroundSize: 'cover', width: '40px', height: '40px',border:0,backgroundColor:'#fff'}}></button>
                </div>
          </div>
       
        </div>

    );
};

export default Chat;