import React,{useEffect, useState} from "react";
import { useGlobalState } from './StateContext';
import FileUpload from "./fileupload";
import {validateEmail} from '../utility'
import { GetTempJWT,GetDocCompletionsAPI,GetUserAccountAPI,GetDocListAPI,deleteDocAPI} from '../api.min'; 

const Doc = ()=>{

    const { isCN,gptModel,TotalAIToken,USERID,handleLogout,handleGetTotalAIToken,currentDocId,currentFileName,handleCurrentDocId } = useGlobalState();
    const [selectedOption, setSelectedOption] = useState('');
    
    const [processing, setProcessing] = useState(false);
    const [docSelected,setDocSelected] = useState(false);
    const [myDocList,setMyDocList] = useState([]);
    var lastPrompt =  JSON.parse(sessionStorage.getItem(currentDocId+"INPUT")) || '';
    var docResult =  JSON.parse(sessionStorage.getItem(currentDocId)) || '';
    const [inputValue, setInputValue] = useState(lastPrompt) || '';
    const [processResult, setProcessResult] = useState(docResult);
    const GetDocList = async ()=>{

      const jwt = await GetTempJWT(USERID);
      const docList = await GetDocListAPI(jwt,USERID);
      setMyDocList(docList);
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
      };

    const RetrieveUserAccount = async()=>{
        const jwt = await GetTempJWT(USERID);
        const totalAIToken = await GetUserAccountAPI(jwt,USERID);
        handleGetTotalAIToken(totalAIToken);
      }
    const handleDocSelection = (event) => {
        setSelectedOption(event.target.value);
        handleCurrentDocId(event.target.value);
        setDocSelected(true);

      };
    const deleteDoc = async ()=>{
      var result = confirm(isCN?'您确定要删除这个文件吗？':'Are you sure you want to delete this file?');    
      if (result)
      {
        const jwt = await GetTempJWT(USERID);
        await deleteDocAPI(jwt,USERID,currentDocId);
      }
    };

    const handleDocProcess = async ()=>{
        if (TotalAIToken<30){
            alert(isCN?'您的AI豆已经用完。请购买充值码进行充值。\n\n也可以分享GPT Mate以获得赠送豆, 分享方法在“我的帐户”里。':'Your AI Tokens have been exhausted. Please acquire a recharge code to top up. You can also share GPT Mate to receive bonus AI Tokens. The sharing method is in "My Account".');    
          }
          else{   
            if (gptModel === 'GPT-4' && TotalAIToken<50)   
            {    
              alert(isCN?'您的AI豆余额不够使用GPT-4, 请购买充值码进行充值。\n\n也可以分享GPT Mate以获得赠送豆, 分享方法在“我的帐户”里。':'Your AI Token balance is not sufficient to use GPT-4, please acquire a recharge code to top up. You can also share GPT Mate to receive bonus AI Tokens. The sharing method is in "My Account".');   
            }
            else{
              setProcessing(true);
              try {             
                //add prompt into temp message list

                if (!validateEmail(USERID)){
                  handleLogout();
                }
                const jwt = await GetTempJWT(USERID);
                const result = await GetDocCompletionsAPI(jwt,USERID,currentDocId,currentFileName, gptModel,inputValue);
                let temp = result.replace(/\n/g, '\u00a0\n');

                sessionStorage.setItem(currentDocId,JSON.stringify(temp));
                sessionStorage.setItem(currentDocId+"INPUT",JSON.stringify(inputValue));

                var docResult = JSON.parse(sessionStorage.getItem(currentDocId));
                setProcessResult(docResult);
                setProcessing(false);  
                RetrieveUserAccount();
  
              } catch (error) {
                  setProcessing(false);
              }
            }
          }


    };

    const handleBlur = () => {
      sessionStorage.setItem(currentDocId+"INPUT",JSON.stringify(inputValue));
    };
  
    const handleCopy = () => {
      navigator.clipboard.writeText(processResult)
        .then(() => {
         alert(isCN?'已拷贝到剪贴板':'Copied to clipboard');    
        })
        .catch((error) => {
          console.error('Error copying text: ', error);
        });
    };

    const  handleSpeech = () =>{
      if ('speechSynthesis' in window) {
        const speech = new SpeechSynthesisUtterance(processResult);
        window.speechSynthesis.speak(speech);
      } else {
        console.error('SpeechSynthesis API not supported');
        // Optionally, you can provide a fallback for browsers that do not support SpeechSynthesis API
      }
    };

    useEffect(()=>{

      var docResult = JSON.parse(sessionStorage.getItem(currentDocId));
      setProcessResult(docResult);

      var lastPrompt = JSON.parse(sessionStorage.getItem(currentDocId+"INPUT"));
      setInputValue(lastPrompt);
    
    },[currentDocId]);

    useEffect(()=>{
      
      GetDocList();

    },[myDocList]);
    return (

        <div style={{fontSize:20,   height: '90vh',  overflowY: 'auto'}}>
          
            <ul>
                <h4>{isCN?'文件处理':'Process Document'}</h4>   
            </ul>
            <div style={{position:'fixed',top:1,right:10,fontSize:12,backgroundColor:'#0EE90',maxHeight:40}}>
                  <p>{TotalAIToken}</p>
            </div>

            <div style={{position:'fixed',top:15,right:10,fontSize:12,backgroundColor:'#0EE90',maxHeight:40}}>
                  <p>{gptModel}</p>
            </div>  
            
            <ul>
               
            </ul>
            <ul>
                <h5 style={{width:'90%'}}>{isCN?'第一步：上传或选择已上传的PDF或MS Word文件':'Step I. Upload or select an uploaded PDF or MS Word file'}</h5>
                <div style={{border:'1px solid black', width:'90%',borderRadius: '5px',}}>
                  <br/>
              
                      {myDocList.length<5 && <FileUpload/> }
                  
                       <hr/>
                        <li style={{listStyle:'none',paddingLeft:'10px'}}>
                           <div style={{width:'95%',display:'inline-block'}}>
                                  {isCN?'请选择已上传的文件：':'Please select the uploaded file:'}
                             
                              </div>
      
                       </li>

            
                        {myDocList.map((item, index) => (
                          
                        <li key={index} style={{listStyle:'none',paddingLeft:'10px'}}>
                          <br/>
                          <div style={{width:'10%',display:'inline-block'}}>
                              <input style={{width:'20px',height:'20px',verticalAlign:'middle', borderWidth:'8px'}}
                              type="radio"
                              value={item.docId}
                              checked={selectedOption === item.docId}
                              onChange={handleDocSelection}/>
                          </div>

                          <div style={{width:'65%',display:'inline-block'}}>
                              {item.fileName}
                          </div>

                          <div style={{width:'20%',display:'inline-block'}}>
                            {selectedOption === item.docId &&<button onClick={deleteDoc} style={{fontSize:14}}>{isCN?'删除':'Delete'}</button>}
                        </div>

                        </li>
                      ))}
               
                    <br/>               
                    <br/>
                </div>
                <h5>{isCN?'第二步：请输入具体要求':'Step II. Please enter specific requirements'}</h5>
             
                <div>
       
                    <textarea maxLength={500} value={inputValue}  onChange={handleInputChange} onBlur={handleBlur}
                        style={{width:'85%', left:20,bottom: 25,fontSize:20, resize: 'none',outline: 'none',borderRadius: '5px', minHeight: '140px',maxHeight: '200px',maxWidth:'400px' ,overflow:'auto' }}
                        rows={1} 
                        placeholder={isCN?"举例：请用英文概括上面的文件\n或者：根据上面的文件写出一封感谢信\n或者：请用杜甫的风格改写上面的文章\n或者：请指出这篇文章的正误\n或者：请问目前国际学界对这篇文章观点的看法，请用西班牙语回答":
                        'For example: Please summarize the above document in English.\nOr: Write a thank-you letter based on the above document.\nOr: Rewrite the above article in the style of Du Fu.\nOr: Please point out the correctness of this article.\nOr: What is the current international academic view on the viewpoints of this article? Please answer in Spanish."'}
                     />
                </div>
                <h5>{isCN?'第三步：分析处理':'Step III. Analyze and process'}</h5>
                {processing && <img src="loading.gif" width={30}/>}
                { docSelected && myDocList.length>0 &&
                    <div>
                 
                        <button onClick={handleDocProcess} style={{fontSize:20}}>{isCN?'按这里开始处理文件':'please click here to start'}</button>
                    
            
                        <p>{isCN?'结果：':'Result: '}</p>
                
              
                        <div style={{width:'80%', whiteSpace: 'pre-line' }}>
                        
                            {processResult}
                        
                        </div>
                    </div>
                }
              <br/>

              {/* <button onClick={handleCopy}>  {isCN?'拷贝':' Copy'} </button> */}
              &nbsp; 
                   <img src="copy_icon.png" width={10}  onClick={()=> handleCopy()}/>


              &nbsp;  &nbsp;
                   <img src="speaker.png" width={15}  onClick={()=> handleSpeech()}/>
              <br/>  <br/><br/>
              <br/>
              <br/>
            </ul>
        </div>
    );
}
export default Doc