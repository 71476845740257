import React,{useState,useEffect} from "react";
import { useGlobalState } from './StateContext';
import {GetUserAccountAPI,GetTempJWT,UseRenewalCodeAPI,VerifyRenewalCodeAPI} from '../api.min';
import { blue } from "@mui/material/colors";
import QRCode from 'qrcode.react';
import './account.css'; 
import { helpMessages } from './constants';

const Account = ()=>{

    const {isCN, USERID,handleGetTotalAIToken,TotalAIToken,MyPromoteURL,UserName} = useGlobalState();
    const RetrieveUserAccount = async()=>{
        const jwt = await GetTempJWT(USERID);
        const totalAIToken = await GetUserAccountAPI(jwt,USERID);
        handleGetTotalAIToken(totalAIToken);
    }
    const [inputValue, setInputValue] = useState('');

    const [disableRechargeButton,seDisableRechargeButtont] = useState(true);
    const handleInputChange = (event) => {
        setInputValue(event.target.value);
        if (event.target.value.length === 24){
            seDisableRechargeButtont(false);
        }
        else{
            seDisableRechargeButtont(true);
        }
      };

    const handleRenew = async() => {
        const jwt = await GetTempJWT(USERID);
        setInputValue(event.target.value);
        const result = await UseRenewalCodeAPI(jwt,USERID,inputValue);
        handleGetTotalAIToken(result);
    };

    useEffect(()=>{
            RetrieveUserAccount();
         }
    );

    const [disableVerifyButton,seDisableVerifyButtont] = useState(true);

    const [validationResult,setValidationResult] = useState('');
    const [inputVerifyCodeValue, setInputVerifyCodeValue] = useState('');

    const handleInputVerifyCodeChange = (event) => {
        setInputVerifyCodeValue(event.target.value);
        if (event.target.value.length === 24){
            seDisableVerifyButtont(false);
        }
        else{
            seDisableVerifyButtont(true);
        }
      };

    const handleVerifyCode = async() => {
        const jwt = await GetTempJWT(USERID);
        setInputVerifyCodeValue(event.target.value);
        const result = await VerifyRenewalCodeAPI(jwt,USERID,inputVerifyCodeValue);
        setValidationResult(result);
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(MyPromoteURL)
          .then(() => {
           alert(isCN?'已拷贝到剪贴板':'Copied to clipboard');    
          })
          .catch((error) => {
            console.error('Error copying text: ', error);
          });
      };

    const getLocalizedMessage = (key, isCN) => helpMessages[isCN ? 'cn' : 'en'][key];

    return (
        <div style={{width:'90%', height: '100vh', overflowY: 'auto'}}>
          
            <ul>
                <h4>{isCN?'我的帐户':'My account'}</h4>
            </ul>
            <ul>
                <p>{isCN?'您好,':'Hi,'}&nbsp;{UserName}!</p>
            </ul>
            <ul>
                <p>{isCN?'AI豆余额： ':'Total AI Token: '}{TotalAIToken}</p>

                <textarea maxLength={24} value={inputValue}  style={{fontSize:18,height:30, width:280}} 
                    onChange={handleInputChange} placeholder={isCN?'请输入充值码：':'Recharge Code: '}
                    rows={1} // Set initial rows to 1
                    /><br/>
                <button style={{fontSize:18,color:blue}} onClick={handleRenew} disabled={disableRechargeButton}>{isCN?'充值':'Recharge'} </button> &nbsp;
        
                <br/>
                <hr/>
                <h4>{getLocalizedMessage('title', isCN)}</h4>
                <strong><p>{getLocalizedMessage('q1', isCN)}</p></strong>
                <p>{getLocalizedMessage('a1', isCN)}</p>

                <strong><p>{getLocalizedMessage('q2', isCN)}</p></strong>

                <p>{getLocalizedMessage('a2', isCN)}</p>

                <strong><p>{getLocalizedMessage('q3', isCN)}</p></strong>
                <p>{getLocalizedMessage('a3', isCN)}</p>
                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                 {isCN?'点击':'Click '}
                &nbsp;
                 <a href= {isCN?"https://smallsoft.com.au/gptcode/":'https://smallsoft.com.au/gptcode_en/'} target="_blank" rel="noreferrer">{isCN?'这里':'here'}</a>
                &nbsp;
                 {isCN?'购买充值码':' to purchase recharge code.'}

                <strong><p>{getLocalizedMessage('q4', isCN)}</p></strong>
                <p>{isCN?'答：每次提问或请求使用的AI豆分别是：':'A: '}</p>
                <ul  className="price-item">

                         <li>
                            <div className="price-item-column1">{isCN?'AI 聊天':'Chat'} - GPT-3.5</div>
                            <div  className="price-item-column2">  1 </div>
                        </li>

                        <li >
                            <div  className="price-item-column1">{isCN?'AI 聊天':'Chat'} - GPT-4</div>
                            <div  className="price-item-column2">  15 </div>
                        </li>

                        <li>
                            <div  className="price-item-column1">{isCN?'文件处理':'Process Doc'} GPT-3.5</div>
                            <div  className="price-item-column2">  30 </div>
                        </li>

                        <li>
                            <div  className="price-item-column1">{isCN?'文件处理':'Process Doc'} GPT-4</div>
                            <div  className="price-item-column2">  50 </div>
                        </li>

                        <li>
                            <div  className="price-item-column1">{isCN?'文字生成图片':'Generate Image'}</div>
                            <div  className="price-item-column2">  50 </div>
                        </li>

                </ul>
                <p>{getLocalizedMessage('a4', isCN)}</p>

                <strong><p>{getLocalizedMessage('q5', isCN)}</p></strong>

                <p>{getLocalizedMessage('a5', isCN)}</p>
                
                <textarea maxLength={24} value={inputVerifyCodeValue}  style={{fontSize:18,height:30, width:280}} 
                        onChange={handleInputVerifyCodeChange} placeholder={isCN?'请输入充值码：':'Recharge Code: '}
                        rows={1} // Set initial rows to 1
                        /><br/>
                    <button style={{fontSize:18,color:blue}} onClick={handleVerifyCode} disabled={disableVerifyButton}>{isCN?'查验':'Verify recharge code'}</button> &nbsp;
                {validationResult}
                <strong><p style={{color:'#ff5252'}}>{getLocalizedMessage('q6', isCN)}</p></strong>

                <p>{getLocalizedMessage('a6', isCN)}</p>
                
                <textarea maxLength={200} value={MyPromoteURL}  style={{fontSize:12,height:30, width:280}} 
                disabled="true"
                        rows={2} // Set initial rows to 1
                        /><br/>
                          <button onClick={handleCopy}>  {isCN?'拷贝':' Copy'} </button>
                    <p>({getLocalizedMessage('a61', isCN)})</p>
                {<QRCode value={MyPromoteURL} />}

                <hr/>
                <strong><p>{isCN?'问：如何提供反馈信息？':'Q: How to Provide Feedback?'}</p></strong>
                <p>{isCN?'答：请点击':'A: Click'} <a href="https://shiol.com/contact" target="_blank" rel="noreferrer">{isCN?'这里':'here'}</a></p>
            </ul>
            <br/><br/>  <br/><br/>  <br/><br/>
        </div>
    );
}
export default Account