import React from "react";
const Video = ()=>{


    return (

<div>
        <h1>generate video</h1>
        <label>Exciting developments are on the way!</label>
        </div>
    );
}
export default Video