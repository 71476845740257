import React from "react";
import { useGlobalState } from './StateContext';
import Layout from './layout';
import Login from './login';
import { useParams } from 'react-router-dom';
const Wrap = ()=>{
    const { isLoggedIn,handleAgentID } = useGlobalState();
    let { agentID } = useParams(); 
    if (agentID) {
        handleAgentID(agentID);
    }else{
        handleAgentID('');
    }

    return (
        <div> {isLoggedIn ?( <Layout/>):( <Login />)} </div>

    )
}
export default Wrap