
export const helpMessages = {
    en: {
        title: "FAQ",
        q1: "Q: What is AI Token?",
        a1: "A: AI Token is a digital currency used to purchase and utilize AI services provided by GPT Mate. Users buy these tokens to unlock features or use AI models for various tasks.",
        
        q2: "Q: Please introduce the recharge code.",
        a2: "Q: The recharge code is a special string of characters used to top up the user's AI Tokens. The recharge code is valid only once, so please do not disclose it to others. There are two types of recharge codes: those starting with '01' can add 300 AI Tokens, and those starting with '02' can add 3000 AI Tokens.",

        q3: "Q: How to purchase and use AI Token?",
        a3: "A: You can purchase recharge codes through distributors certified by Smallsoft company, and top up their total AI Token by entering the recharge code above on this webpage. You can also purchase recharge code by clicking the link below.'",

        q4: "Q: How many AI Tokens are consumed per question submission?",
        a4: "Note: Document processing will be charged based on file size, with usage measured in AI Tokens. During the current trial period, calculations will be made at fixed, discounted prices per use.",
    
        q5: "Q: How to verify the authenticity and validity of a recharge code?",
        a5: "A: Please enter 'recharge code' below for verification.",

        q6: "Q: How to get free AI Tokens?",
        a6: "A: Share the link below with your friends. When they successfully sign up, you will automatically receive 50 AI Tokens.",
        a61: "If the above content is empty, please log out and log back in to resolve the issue.",
        q7: "",
        a7: "",
    },
    cn: {
        title: "常见问题解答",
        q1: "问：什么是AI豆？",
        a1: "答：AI豆是一种数字货币，用于购买和使用GPT Mate平台提供的人工智能服务。用户购买它来解锁功能或使用AI模型进行各种任务。",
        
        q2: "问：请介绍一下充值码",
        a2: "答：充值码是一串特殊的字符，用于增加用户的AI豆。充值码一次性有效，请不要泄漏给其他人。充值码有两种：01开头的充值码可以增加300个AI豆，02开头的充值码可以增加3000个AI豆。",

        q3: "问：如何购买充值码？",
        a3: "答：用户可以通过Smallsoft公司认定的分销商购买充值码，您也可以点击下面的链接购买充值码。",

        q4: "问：请问每次提问使用多少AI豆？",
        a4: "注意：文件处理将根据文件大小计算用豆量，目前试用期以固定的最低优惠价格按次计算。",

        q5: "问：如何验证充值码的真假和有效性？",
        a5: "答：请在下面输入充值码进行验证",

        q6: "问：如何获得免费AI豆？",
        a6: "答：把下面的链接或者二维码分享给好朋友， 当他们成功注册后你就会自动获得50个AI豆。",
        a61: "如果上面内容为空，请退出重新登陆即可解决。",
        q7: "",
        a7: "",
    },
    };

    export const API_BASE_URL = 'https://api.shiol.com';

