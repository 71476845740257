import React,{useState} from "react";
import { useGlobalState } from './StateContext';
import {GetTempJWT ,GetTipsAndNewsAPI } from "../api.min";

const Setting = ()=>{
    
    const {isCN,gptModel, handleSetCN,handleSetGptModel,handleWelcome,USERID,handleTipsAndNews} = useGlobalState();
    const [selectedOptionLang, setSelectedOptionLang] = useState(isCN?'CN':'EN');
    const [selectedOptionModel, setSelectedOptionModel] = useState(gptModel);
    const handleSelectLanguage = (event) => {
      setSelectedOptionLang(event.target.value);
      const temp = event.target.value === 'CN'?true:false;
      handleSetCN(temp);
    };
  
    const handleSelectModel = (event) => {
        setSelectedOptionModel(event.target.value);
        handleSetGptModel(event.target.value);
      };
    const handleClear = async ()=>{

        sessionStorage.clear();
        handleWelcome(true);

        //refresh Tips and News
        const jwt = await GetTempJWT(USERID);
        const result = await GetTipsAndNewsAPI(jwt,USERID);
        const tipList = result.filter(item=>item.category ==='TIP');
        const newsList = result.filter(item=>item.category ==='NEWS');

        const latestTIP = tipList.reduce((latest,current)=>{
            const latestDate = new Date(latest.createdDateTime);
            const currentDate = new Date(current.createdDateTime);
            return latestDate > currentDate ? latest : current;
        });
        const latestNews = newsList.reduce((latest,current)=>{
            const latestDate = new Date(latest.createdDateTime);
            const currentDate = new Date(current.createdDateTime);
            return latestDate > currentDate ? latest : current;
        });


        handleTipsAndNews(latestTIP.contentCN,latestTIP.contentEN, latestNews.contentCN,latestNews.contentEN);
    };

    return (

            <div style={{ height: '90vh', overflowY: 'auto'}}>
                <ul>
            
                
                <h4>{isCN? <label>设置</label>:<label>Settings</label>}</h4>

                <div>
           
                    <label>
                        <input
                         name="language"
                        type="radio"
                        value="EN"
                        checked={selectedOptionLang === 'EN'}
                        onChange={handleSelectLanguage}
                        />
                        English
                    </label> &nbsp;&nbsp;
                    <label>
                        <input
                         name="language"
                        type="radio"
                        value="CN"
                        checked={selectedOptionLang === 'CN'}
                        onChange={handleSelectLanguage}
                        />
                        中文
                    </label>

                </div>

               <br/><br/><br/>
     
                <div>
       
                    <label>
                        <input
                         name="model"
                        type="radio"
                        value="GPT-3.5"
                        checked={selectedOptionModel === 'GPT-3.5'}
                        onChange={handleSelectModel}
                        />
                        GPT-3.5
                    </label> &nbsp;&nbsp;
                    <label>
                        <input
                         name="model"
                        type="radio"
                        value="GPT-4"
                        checked={selectedOptionModel === 'GPT-4'}
                        onChange={handleSelectModel}
                        />
                        GPT-4
                    </label>

                </div>

                <br/><br/>
                <button onClick={handleClear} style={{ fontSize:18}}>{isCN?'清除历史记录':'Clear browser history'} </button>

                <br/><br/>
                <br/><br/>
                
                {isCN? <div style={{width:'90%',fontSize:14}}>
                      <p>小技巧：</p>
                    <p>用户的使用记录不保存在服务器端，只保存在用户浏览器中。清除历史记录可以提高运行效率。</p>
                  </div>:
                  <div style={{width:'90%',fontSize:14}}>
                       <p>Tip: </p>
                      <p>{'The user\'s usage records are not stored on the server side, only in the user\'s browser. Clearing the browsing history can improve performance.'}</p>

                </div>}
            </ul>
            <br/><br/>  <br/><br/>
        </div>
    );
}
export default Setting
