import React from "react";
import { useGlobalState } from './StateContext';
const About = ()=>{
    const {isCN} = useGlobalState();
    return (
        <div style={{width:'90%',height: '100vh', overflowY: 'auto'}}>
          
            <ul>
                <h4>{isCN?'关于GPT Mate':'About'}</h4>
       
                {isCN &&
                    <p>GPT Mate是由Smallsoft Pty Ltd公司开发的人工智能(AI)服务，系统内核直接由OpenAI公司的大语言模型驱动。
                    <br/><br/>
                    目前直接调用OpenAI的模型包括：<strong>GPT-3.5-Turbo-0125, GPT-4-0125-preview, DALL·E 3</strong>
                    <br/><br/>
                    
                    </p>}
                {!isCN &&
                    <p>
                    {'GPT Mate is an artificial intelligence (AI) service developed by Smallsoft Pty Ltd, with the system core powered by OpenAI\'s GPT-3.5, GPT-4 and DALL·E 3.'}
                    </p>}

            </ul>
            <br/><br/><br/><br/><br/><br/>
            <ul>
                <hr/>
                <p><a href="https://smallsoft.com.au/terms-and-conditions-for-the-gpt-mate-mobile-app/" target="_blank" rel="noreferrer">{isCN?'使用条款和条件':'Terms and Conditions'}</a></p>
             
                <p><a href="https://smallsoft.com.au/privacy-policy-for-gpt-mate/" target="_blank" rel="noreferrer">{isCN?'GPT Mate 的隐私政策':'Privacy Policy for GPT Mate'}</a></p>
              
                <p><a href="https://shiol.com/contact" target="_blank" rel="noreferrer">{isCN?'联系我们':'Contact us'}</a></p>
            </ul>
            <ul>
                <br/><br/><br/><br/><br/>
                <p style={{fontSize:10}}>© 2024 Smallsoft Pty Ltd</p>
            </ul>
        </div>
    );
}
export default About