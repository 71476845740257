import React from 'react';
import './movingDivs.css'; // Import CSS file with animation styles
import PropTypes from 'prop-types';
class MovingDivs extends React.Component {
  render() {
    const { text } = this.props;
    return (
      <div className="container">
        <div className="moving-div">{text}</div>
      </div>
    );
  }
}
MovingDivs.propTypes = {
  text: PropTypes.string.isRequired // 'text' prop is required and must be a string
};
export default MovingDivs;
