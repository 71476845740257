import React from 'react';
import Wrap from './wrap';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
const StartPage = () =>{
    return(
            <Router>
            <Routes>
              <Route path="/:agentID?" element={<Wrap />} />
            </Routes>
          </Router>
    
    );
}
export default StartPage;