import React, {useEffect, useState} from "react";
import { useGlobalState } from './StateContext';
import { GetTempJWT, GenerateImageAPI} from '../api.min'; 
import './image.css'; // Import CSS file for styling
import QRCode from 'qrcode.react';

const Image = () => {
  const { USERID,isCN,TotalAIToken,MyPromoteURL} = useGlobalState();
    const [inputValue, setInputValue] = useState('');
    const [processing, setProcessing] = useState(false);
    const [resultUrl, setResultUrl] = useState('');

    const handleInputChange = (event) => {
      setInputValue(event.target.value);
    };

    const handleTask = async() => {
      if (TotalAIToken<50){

        setShowAlert(true);
       }
      else
      {
        try{

            setProcessing(true); 

            const jwt = await GetTempJWT(USERID);
            const result = await GenerateImageAPI(jwt,USERID,inputValue);
            setProcessing(false); 
            setResultUrl(result);

        }
        catch (error) {
          setProcessing(false);
          console.error('Error calling Azure OpenAI API:', error);
        }
      }
    };

    useEffect(()=>{


    },[resultUrl,processing]);


    const [showAlert, setShowAlert] = useState(false);
  
    const handleCloseAlert = () => {
      setShowAlert(false);
    };

    const handleCopy = () => {
      navigator.clipboard.writeText(MyPromoteURL)
        .then(() => {
         alert(isCN?'已拷贝到剪贴板':'Copied to clipboard');    
        })
        .catch((error) => {
          console.error('Error copying text: ', error);
        });
    };

    return (

      <div style={{ height: '90vh', overflowY: 'auto'}}>    
                {showAlert && (
                  <div className="modal-overlay">
                    <div className="modal">
                      <div className="modal-content">
                        <p>{isCN?'您的AI豆余额不足，请购买充值码进行充值。':'Your AI Total balance is insufficient, please acquire a recharge code to top up.'}</p>
              
                        <strong><p style={{color:'#ff5252'}}>{isCN?'如想获得赠豆，请分享链接:':'To receive complimentary AI Tokens, kindly share the link.'}</p></strong>
                      
                     
                       {<QRCode value={MyPromoteURL}  />}
                      <br/><br/>

                      <button onClick={handleCopy}>  {isCN?'拷贝':' Copy'} </button>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                      <button onClick={handleCloseAlert}>  {isCN?'关闭':'Close'} </button>
                     
                      </div>

                        
                    </div>
                  </div>
                )}
           
            <ul><h4>{isCN?'生成图像':'Generate image'}</h4></ul>
            <ul>
                  <div style={{position:'fixed',top:1,right:10,fontSize:12,backgroundColor:'#0EE90',maxHeight:40}}>
                        <p>{TotalAIToken}</p>
                  </div>
                  <div style={{position:'fixed',top:15,right:10,fontSize:12,backgroundColor:'#0EE90',maxHeight:40}}>
                    <p>DALL·E 3</p>
                  </div>   
                <br/>
                <textarea maxLength={200} value={inputValue}
                  onChange={handleInputChange} placeholder={isCN?'请输入提示词，描述您想要的图像':'Please enter a prompt describing the image you want.'}
                      style={{width:'90%',fontSize:18, resize: 'none', minHeight: '180px',maxHeight: '200px',maxWidth:'400px' ,overflow:'auto' }}
                      rows={1}  />
                      <br/><br/>
                  <button style={{fontSize:18}} onClick={handleTask}  disabled={processing}>{isCN?'开始处理':'Start generating'}</button> 
                 <br/>
                  {processing && <img src="imageloading.gif" width={160}/>}
                  <br/><br/>
                  <img src={resultUrl} width={300}></img>
                  <br/>
                  <br/>
  
                  {isCN? <div style={{width:'90%'}}>
                    <p>提示技巧：</p>
                    <p>提示可以由任何单词组成，但请考虑如何向某人解释一张图片。 “主题+形容词+风格”的形式通常效果较好。</p>
                    <p>例如：</p>
                    <p>一只走在森林里的狐狸的特写，具有逼真的质感和照明</p>
                  </div>:
                  <div style={{width:'90%'}}>
                       <p>Tip: Prompt structure</p>
                      <p>{'Prompts can be composed of any words, but think about how you might explain an image to someone. The form of \'subject + adjectives + styles\' tends to work well.'}</p>
                      <p>Prompt</p>
                      <p>A close-up of a fox walking through the forest, photorealistic texture and lighting</p>
                    
                    </div>}
<br/><br/>
            </ul>
            <br/><br/><br/><br/>
        </div>
      );
};

export default Image;


