import React from "react";

const MathLesson = ()=>{

    return (

        <div>
            <p>数学课堂正在开发中...</p>
        </div>
    );
}
export default MathLesson;