import React, { useEffect } from 'react';

import './App.css'; // Import CSS file for styling
import StartPage from './components/StartPage'
import { StateProvider } from './components/StateContext';
import './components/centeredpage.css';

const App = () => {
  useEffect(() => {
    return () => {

    };
  }, []);
  return (

    <StateProvider>
      <div className='centered-page'>
        <StartPage/>
      </div>
    </StateProvider>

  );
};

export default App;
