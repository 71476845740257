import React,{useState} from "react";
import EnglishLesson from "./englishEdu/englishLesson";
import MathLesson from "./mathEdu/mathLesson";

const Industry = ()=>{
    const [selectedOption, setSelectedOption] = useState('option1');
    const [isEnglishOpen, setEnglishOpen] = useState(false);
    const [isMathOpen, setMathOpen] = useState(false);

    const handleOptionChange1 = (event) => {
        setSelectedOption(event.target.value);
        setEnglishOpen(true);
        setMathOpen(false);
      };
    
    const handleOptionChange2 = (event) => {
        setSelectedOption(event.target.value);
        setEnglishOpen(false);
        setMathOpen(true);
    };
      
    return (
        <ul>
        <div>
    
            <strong>第一步：请选应用场景</strong>
            <div>
                    <label>
                        <input
                        type="radio"
                        value="English"
                        checked={selectedOption === 'English'}
                        onChange={handleOptionChange1}
                        />
                        英语教培
                    </label>
                    <label>
                        <input
                        type="radio"
                        value="Math"
                        checked={selectedOption === 'Math'}
                        onChange={handleOptionChange2}
                        />
                        奥数教室
                    </label>

            </div>

            {isEnglishOpen && <EnglishLesson/>}
            {isMathOpen && <MathLesson/>}

        </div>
        </ul>
    );
}
export default Industry