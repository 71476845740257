import React,{useState} from "react";
import { useGlobalState } from '../StateContext';
import { GetTempJWT,EnglishLessionTaskAPI} from '../../api.min'; 

const EnglishLesson = ()=>{
    const { USERID} = useGlobalState();
    const [isExpanded1, setIsExpanded1] = useState(false);
    const [isExpanded2, setIsExpanded2] = useState(false);

    const [taskResult, setTaskResult] = useState('');

    const toggleExpand1 = () => {
        setIsExpanded1(!isExpanded1);
    };

    const toggleExpand2 = () => {
        setIsExpanded2(!isExpanded2);
    };

    const [selectedOption, setSelectedOption] = useState('TaskType1');
    const [selectedTaskType, setSelectedTaskType] = useState('AI出题');

    const [selectedMaterial,setSelectedMaterial] = useState('KET');
    const handleOptionChange2 = (event) => {
        setSelectedOption(event.target.value);
        switch (event.target.value) {
            case 'KET':
                setSelectedMaterial('KET');
              break;
            case 'NCE':
                setSelectedMaterial('NCE');
              break;

            default:
              
          }
        
      };

    const handleOptionChange1 = (event) => {
        setSelectedOption(event.target.value);
        switch (event.target.value) {
            case 'TaskType1':
                setSelectedTaskType('AI出题');
              break;
            case 'TaskType2':
                setSelectedTaskType('英译中');
              break;
              case 'TaskType3':
                setSelectedTaskType('向AI提问');
              break;
            case 'TaskType4':
                setSelectedTaskType('句子优化');
              break;
              case 'TaskType5':
                setSelectedTaskType('批改作业');
              break;

            default:
              
          }
        
      };
    
    const [inputValue, setInputValue] = useState('');
    

    const handleTask = async() => {
        const jwt = await GetTempJWT(USERID);
        setInputValue(event.target.value);
        setTaskResult(inputValue);
        const result = await EnglishLessionTaskAPI(jwt,USERID,selectedOption,inputValue);
        setTaskResult(result);
    };

    const clearTask = async() => {
        setTaskResult('');
        setInputValue('');
    };
    const handleInputChange = (event) => {
        setInputValue(event.target.value);
       // adjustInputHeight();
      };
    return (

        <div>
            <p><strong>第二步：请选择课程（教材）</strong></p>

            <div>
            <label>
                    <input
                    type="radio"
                    value="KET"
                    checked={selectedMaterial === 'KET'}
                    onChange={handleOptionChange2}
                    />
                    KET
                </label>&nbsp;&nbsp;
                <label>
                    <input
                    type="radio"
                    value="NCE"
                    checked={selectedMaterial === 'NCE'}
                    onChange={handleOptionChange2}
                    />
                    新概念英语
                </label>&nbsp;&nbsp;
            </div>

           
            {selectedMaterial === 'KET' && <div>
            <p>KET &nbsp;
            <button onClick={toggleExpand1}>
                {isExpanded1 ? 'Collapse' : 'Expand'}
            </button></p>
            {isExpanded1 && (
                <div>   The Key English Test (KET) is an internationally recognized English language proficiency exam administered by Cambridge Assessment English, part of the University of Cambridge. It is designed to assess the basic English language skills of individuals at the A2 level of the Common European Framework of Reference for Languages (CEFR), which is considered the beginner or elementary level.

        The KET exam evaluates candidates`` abilities in the four language skills: reading, writing, listening, and speaking. It covers everyday situations and topics commonly encountered in English-speaking environments, such as shopping, travel, and social interactions. The test aims to assess a candidate`s ability to understand and produce simple sentences and to communicate in basic English.

        The KET exam consists of three main components:

        Reading and Writing: This section assesses candidates`` ability to understand short texts, signs, and notices, as well as their ability to write short messages, postcards, and simple informal letters.
        Listening: In this part of the exam, candidates listen to recordings of everyday conversations and monologues, and they are required to answer multiple-choice questions or fill in missing information based on what they hear.
        Speaking: The speaking component involves a face-to-face interaction with an examiner, where candidates engage in conversation on familiar topics. They may be asked to describe people, places, or events, express opinions, and respond to questions.
        The KET exam is suitable for learners who have a basic understanding of English and wish to demonstrate their proficiency at the beginner level. It provides a valuable benchmark for students, teachers, and employers to assess language skills and progress in English language learning. Additionally, achieving a KET qualification can enhance opportunities for study, work, and travel in English-speaking countries.
                </div>
            )}
       
            <p><strong>第三步：选择任务类型</strong></p>
            <div>

                <label>
                    <input
                    type="radio"
                    value="TaskType1"
                    checked={selectedOption === 'TaskType1'}
                    onChange={handleOptionChange1}
                    />
                    AI出题
                </label>&nbsp;&nbsp;
                <label>
                    <input
                    type="radio"
                    value="TaskType2"
                    checked={selectedOption === 'TaskType2'}
                    onChange={handleOptionChange1}
                    />
                    英译中
                </label>&nbsp;&nbsp;
                <label>
                    <input
                    type="radio"
                    value="TaskType3"
                    checked={selectedOption === 'TaskType3'}
                    onChange={handleOptionChange1}
                    />
                    向AI提问
                </label>&nbsp;&nbsp;
                <label>
                    <input
                    type="radio"
                    value="TaskType4"
                    checked={selectedOption === 'TaskType4'}
                    onChange={handleOptionChange1}
                    />
                    句子优化
                </label>&nbsp;&nbsp;
                <label>
                    <input
                    type="radio"
                    value="TaskType5"
                    checked={selectedOption === 'TaskType5'}
                    onChange={handleOptionChange1}
                    />
                    批改作业
                </label>&nbsp;&nbsp;

            </div>
            <div>
                <br/>
                <p><strong>第四步：</strong></p>
                <textarea maxLength={1000} value={inputValue}
                onChange={handleInputChange} placeholder="请输入要提的问题或处理的内容"
                    style={{width:'100%',fontSize:18, left:20,bottom: 25, resize: 'none', minHeight: '180px',maxHeight: '200px',maxWidth:'400px' ,overflow:'auto' }}
                    rows={1} // Set initial rows to 1
                    />
                    <br/>
                <button style={{fontSize:18}} onClick={handleTask}>开始{selectedTaskType}</button> &nbsp;<button style={{fontSize:18}}  onClick={clearTask}>清除</button>
               <hr/>
                <p>结果：</p>
     
                <p>{taskResult}</p>
            </div>

            </div>}

            {selectedMaterial === 'NCE' && <div>
                    <p>新概念英语 &nbsp;
                    <button onClick={toggleExpand2}>
                        {isExpanded2 ? 'Collapse' : 'Expand'}
                    </button></p>
                    {isExpanded2 && (

            <div>

                        New Concept English is a well-known series of English language learning textbooks authored by Louis Alexander. Originally published in the 1960s, the series has been widely used in China and many other countries as a foundational resource for English language learners. The series is particularly popular among Chinese learners due to its structured approach and clear explanations.

            The New Concept English series typically consists of several levels, each with its own set of textbooks and accompanying audio materials. The series covers various aspects of English language learning, including grammar, vocabulary, reading comprehension, listening, and speaking skills. The textbooks often feature dialogues, exercises, and drills designed to reinforce language concepts and improve fluency.

            One of the notable features of New Concept English is its focus on practical, everyday language use, making it relevant and accessible to learners of all ages and backgrounds. The series has been praised for its systematic approach to teaching English grammar and its emphasis on building a strong foundation in the language.

            Overall, New Concept English is recognized as a comprehensive and effective resource for English language learners, especially those at the beginner and intermediate levels. Its popularity and longevity attest to its enduring value in the field of English language education.
                </div>
               
            )} </div>}
 </div>
    );
}
export default EnglishLesson;