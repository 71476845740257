// StateContext.js
import React, { createContext, useState, useContext,useEffect } from 'react';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';

const StateContext = createContext();

export const StateProvider = ({ children }) => {
  localStorage.clear();
  
  const [tempJWT,setTempJWT] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(Cookies.get('isLoggedIn') === 'true');
  const [isCN, setIsCN] = useState(Cookies.get('isCN') === 'true');
  const [gptModel, setGptModel] = useState(Cookies.get('gptmodel'));
  const [USERID, setUserid] = useState(Cookies.get('userid'));
  const [TotalAIToken, setTotalAIToken] = useState(0);
  const [AgentID,setAgentID] = useState('');
  const [MyPromoteURL, setMyPromoteURL] = useState(Cookies.get('myPromoteURL'));
  const [UserName, setUserName] = useState(Cookies.get('userName'));
  const [showWelcome, setShowWelcome] = useState(true);
  const [todayTipCN,setTodayTipCN] = useState('');
  const [todayNewsCN,setTodayNewsCN] = useState('');
  const [todayTipEN,setTodayTipEN] = useState('');
  const [todayNewsEN,setTodayNewsEN] = useState('');
  const [currentDocId,setCurrentDocId] = useState('');
  const [currentFileName,setCurrentFileName] = useState('');

  const handleJWT = (token) => {
    setTempJWT(token);
  }
  const handleLogin = (useremail) => {
    Cookies.set('isLoggedIn', 'true', { expires: 7 }); // Expires in 7 days
    Cookies.set('userid', useremail.toLowerCase(), { expires: 30 }); // Expires in 7 days

    setUserid(useremail.toLowerCase());
    setIsLoggedIn(true);
  };

  const handleWelcome = (value)=>{
    setShowWelcome(value);
  };
 
  const handleCurrentDocId = (value)=>{
    setCurrentDocId(value);
  };
  const handleCurrentFileName = (value)=>{
    setCurrentFileName(value);
  };
  const handleTipsAndNews = (tipCN,tipEN,newsCN,newsEN)=>{
    setTodayTipCN(tipCN);
    setTodayTipEN(tipEN);
    setTodayNewsCN(newsCN);
    setTodayNewsEN(newsEN);
  };
  const handMyPromoteURL = (url) => {
    Cookies.set('myPromoteURL', "https://ai.shiol.com/"+url, { expires: 30 }); // Expires in 7 days
    setMyPromoteURL("https://ai.shiol.com/"+url);
  };

  const handleUserName= (name) => {
    Cookies.set('userName', name, { expires: 30 }); // Expires in 7 days
    setUserName(name);
  };

  const handleAgentID = (agentid) =>{
    setAgentID(agentid);
  };

  const handleLogout = () => {
    Cookies.remove('isLoggedIn');
    sessionStorage.clear();
    setIsLoggedIn(false);
  };

  const handleGetTotalAIToken = (num) =>{
    setTotalAIToken(num);
  };

  const handleSetCN = (lang) => {

    Cookies.set('isCN', lang?'true':'false', { expires: 7 }); // Expires in 7 days
    setIsCN(lang);
  };

  const handleSetGptModel = (model) => {
    Cookies.set('gptmodel', model, { expires: 7 }); // Expires in 7 days
    setGptModel(model);
  };
  const parseCookie = () => {
    const cookieUserId = Cookies.get('userid');
    if (cookieUserId) {
      setUserid(cookieUserId);
    }

    const cookieIsCN = Cookies.get('isCN');
    if (cookieIsCN){
      setIsCN(Cookies.get('isCN') === 'true');
    }
    else{
      setIsCN(true);
    }

    const cookieGptModel = Cookies.get('gptmodel');
    if (cookieGptModel) {
      setGptModel(cookieGptModel);
    }
    else{
      setGptModel('GPT-3.5');
    }
  };

  useEffect(() => {
    parseCookie();
  }, []);
  return (
    <StateContext.Provider value={{USERID,AgentID,UserName,currentDocId,currentFileName,handleCurrentFileName,handleCurrentDocId,showWelcome,todayTipCN,todayTipEN,todayNewsCN,todayNewsEN,handleTipsAndNews,handleWelcome,handleAgentID,handleUserName,MyPromoteURL, handMyPromoteURL,isLoggedIn, isCN, gptModel, handleSetGptModel, tempJWT,TotalAIToken,handleLogin, handleLogout,handleSetCN,handleJWT,handleGetTotalAIToken,parseCookie }}>
      {children}
    </StateContext.Provider>
  );
};

StateProvider.propTypes = {
  children: PropTypes.node
};


export const useGlobalState = () => {
  return useContext(StateContext);
};