import React from "react";
import { useGlobalState } from './StateContext';

const Introduction = ()=>{
    const {isCN} = useGlobalState();

const containerStyle = {
  width: '100%',
  padding: '1px'
};

const tabContentStyle = {
  width: '100%',
  height: '30vh',
  overflowY: 'auto',
  overflowX: 'hidden' // Add overflowX: 'hidden' to hide horizontal scrollbar if present
};
return (
    <div style={containerStyle}>


    <div style={tabContentStyle}>
  
        <div>
            <h5>{isCN ? '由文字生成高品质图片（由DALLE3驱动)' : 'Generate Image'}</h5>

            <a href="demo_imag9_large.png" target="_blank" rel="noreferrer">
            <img src='demo_image9_small.png' alt="Image" width={320} height={320} />
          </a>

            <a href="demo_image8.png" target="_blank" rel="noreferrer">
            <img src='demo_image8_small.png' alt="Image" width={320} height={320} />
          </a>
            
            <a href="demo_image7.png" target="_blank" rel="noreferrer">
            <img src='demo_image7_small.png' alt="Image" width={320} height={320} />
            </a>

        
     
        
          <img src='demo_image2.png' alt="Image" width={220} height={480} />
          <img src='demo_image3.png' alt="Image" width={240} height={480} />
         

          <img src='demo_image6.png' alt="Image" width={220} height={480} /><br />
          <img src='demo_image8_screen.png' alt="Doc" width={240} height={480} />

          <img src='demo_image9_screen.png' alt="Image" width={240} height={480} />
          

          <h5>{isCN ? 'AI聊天（由最新GPT4模型驱动）' : 'ChatGPT'}</h5>
    

          <h5>{isCN ? '分析处理pdf及word文件(由最新GPT4模型驱动）' : 'Analyze PDF or MS Word'}</h5>
          <img src='demo_doc1.png' alt="Doc" width={240} height={480} />
       
  
      
        </div>
  
    </div>
  </div>

)
}
export default Introduction;