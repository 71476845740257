export const validateEmail = (email) => {
    // Regular expression pattern for validating email addresses
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    // Test the email against the regular expression
    return emailRegex.test(email);
  }

  export const validatePassword = (password) => {
     return password.length>7
  }

  export const validateUsername = (username) => {
    return username.length>4
 }