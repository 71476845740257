import React, { useState,useEffect } from 'react';
import { SHA256 } from 'crypto-js';
import { useGlobalState } from './StateContext';
import { GetTempJWT, SignUpAPI, LoginAPI,SendVerificationCodeAPI,ResetPasswordAPI,SendCodeGetHashAPI} from '../api.min'; 
import {validateEmail,validatePassword,validateUsername} from '../utility'
import Introduction from './introduction';
import './login.css'; 
import Cookies from 'js-cookie';

const Login = () => {

    const { handleLogin,isCN,handleGetTotalAIToken,handleSetCN,AgentID,handMyPromoteURL,handleUserName } = useGlobalState();

    const [agreeTerms, setAgreeTerms] = useState(false);
    const [isLoginOpen,setIsLoginOpen] = useState(true);
    const [isSignUpOpen, setSignUpOpen] = useState(false);
    const [isForgetPasswordOpen, setForgetPasswordOpen] = useState(false);
    const [isResetPasswordOpen, setIsResetPasswordOpen] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [emailLogin, setEmailLogin] = useState('');
    const [passwordLogin, setPasswordLogin] = useState('');
    
    var tempUsernameSignup =  Cookies.get('tempUsernameSignup')||'' ;
    var tempEmailSignup =  Cookies.get('tempEmailSignup') || '';
    var tempPassword1Signup =  Cookies.get('tempPassword1Signup')||'' ;
    var tempPassword2Signup =  Cookies.get('tempPassword2Signup') || '';
    var tempHashedCodeSignup =  Cookies.get('tempHashedCodeSignup') || '';
 
    const [usernameSignUp, setUsernameSignUp] = useState(tempUsernameSignup);
    const [useremailSignUp, setUseremailSignUp] = useState(tempEmailSignup);
    const [passwordSignUp1, setPasswordSignUp1] = useState(tempPassword1Signup);
    const [passwordSignUp2, setPasswordSignUp2] = useState(tempPassword2Signup);
    const [codeSignUp, setCodeSignUp] = useState('');
    const [hashedCodeSignUp, setHashedCodeSignUp] = useState(tempHashedCodeSignup);

    const [useremailForget, setUseremailForget] = useState('');

    const [passwordReset1, setPasswordReset1] = useState('');
    const [passwordReset2, setPasswordReset2] = useState('');
    const [codeReset, setCodeReset] = useState('');

    
    const [btnSendCodeDisabled, setBtnSendCodeDisabled] = useState(false);
    const [remainingTime, setRemainingTime] = useState(0);

    const [selectedOptionLang, setSelectedOptionLang] = useState(isCN?'CN':'EN');
    const handleSelectLanguage = (event) => {
        setSelectedOptionLang(event.target.value);
        const temp = event.target.value === 'CN'?true:false;
        handleSetCN(temp);
      };
    const handleCheckboxChange = () => {
    setAgreeTerms(!agreeTerms);
    };
    const messages = {
        en: {
            loginFail: "Login failed, please check your email and password.",
            checkEmailPassword: "Invalid email or password.",
        },
        cn: {
            loginFail: "登陆失败，请检查邮箱和密码",
            checkEmailPassword: "请检查邮箱和密码",
        },
        };
    const getLocalizedMessage = (key, isCN) => messages[isCN ? 'cn' : 'en'][key];

    const login = async() => {
       try{
       
                if (!validateEmail(emailLogin) || !validatePassword(passwordLogin)) {
                    alert(getLocalizedMessage('checkEmailPassword', isCN));
                    return;
                }
                setShowLoading(true);
                const jwt = await GetTempJWT(emailLogin);
                const newUser = await LoginAPI(jwt, emailLogin,passwordLogin);

                if (newUser.id === '000')
                {
                    setShowLoading(false);
                    alert(getLocalizedMessage('loginFail', isCN));
                }
                else {
                    
                    handleGetTotalAIToken(newUser.totalAIToken);
                    handleLogin(emailLogin);
                    handleUserName(newUser.userName);
                    if(newUser.agentURL)
                    {
                        handMyPromoteURL(newUser.agentURL);
                    }
                    setShowLoading(false);
                }
                
        }catch(error){
            console.error(error);
            alert(getLocalizedMessage('loginFail', isCN));
        }finally{
            setShowLoading(false);
        }

    };

    const signUp = async() => {

        if (SHA256(codeSignUp).toString()!=hashedCodeSignUp){
            alert(isCN?'请检查验证码':'Please check the verification code.')
        }
        else{
            if (validateUsername(usernameSignUp) && validateEmail(useremailSignUp) && passwordSignUp1 === passwordSignUp2 && validatePassword(passwordSignUp1) && validatePassword(passwordSignUp2) ) {          
                const jwt = await GetTempJWT(useremailSignUp);
                const newUser = await SignUpAPI(jwt,usernameSignUp,AgentID, useremailSignUp,passwordSignUp1);

                if (newUser.id === '000')
                {
                    alert(isCN?'该邮箱已经注册':'This email address is already in use.')
                }
                else
                {
                    alert(isCN?'注册成功！':'Congratulations! Your signup was successful.')

                    Cookies.remove('tempUsernameSignup');
                    Cookies.remove('tempEmailSignup');
                    Cookies.remove('tempPassword1Signup');
                    Cookies.remove('tempPassword2Signup');
                    Cookies.remove('tempHashedCodeSignup');

                    handleLogin(useremailSignUp);
                    setSignUpOpen(false);setIsLoginOpen(true);setForgetPasswordOpen(false);setIsResetPasswordOpen(false);
                }
                
            } else {
                alert(isCN?"无效的用户名、密码或邮箱。用户名长度至少为5, 密码长度至少为8。":"Invalid username, password or email address. Please ensure that your username is at least 5 characters long and your password is more than 8 characters long.");
            }
        }
    };
  
    const SendCodeGetHash = async(email) =>{
        setBtnSendCodeDisabled(true);
        setTimeout(() => {
            setBtnSendCodeDisabled(false);
            setRemainingTime(0); // Reset remaining time
          }, 30000); //30 seconds
          setRemainingTime(30);
        const jwt = await GetTempJWT(email);
        const hashedCode = await SendCodeGetHashAPI(jwt, email);
        setHashedCodeSignUp(hashedCode); 

        //Expiration time in seconds: 5 minutes
        Cookies.set('tempUsernameSignup',usernameSignUp, { expires: 5 / (24 * 60 ) });
        Cookies.set('tempEmailSignup',useremailSignUp, { expires: 5 / (24 * 60 ) });
        Cookies.set('tempPassword1Signup',passwordSignUp1, { expires: 5 / (24 * 60 ) });
        Cookies.set('tempPassword2Signup',passwordSignUp2, { expires: 5 / (24 * 60 ) });
        Cookies.set('tempHashedCodeSignup',hashedCode,{ expires: 5 / (24 * 60 ) });

    };
    useEffect(() => {
        let timer = null;
        if (remainingTime > 0) {
          timer = setInterval(() => {
            setRemainingTime(prevTime => prevTime - 1);
          }, 1000); // Update remaining time every second
        }
        return () => clearInterval(timer);
      }, [remainingTime]);

    const forgetPassword = async() => {
       
        if (validateEmail(useremailForget)){

            const jwt = await GetTempJWT(useremailForget);
            const result = await SendVerificationCodeAPI(jwt,useremailForget);
            if (result == "SUCCESS"){
                setIsResetPasswordOpen(true);
                setSignUpOpen(false);setIsLoginOpen(false);setForgetPasswordOpen(false);
            }
            else{
                alert(isCN?'该邮箱未注册':'The provided email address is not currently on our records.')
            }

        } else {
            alert(isCN?'请检查邮箱地址':'Invalid email');
        }
    };

    const resetPassword = async() => {
        if ( passwordReset1 === passwordReset2 && validatePassword(passwordReset1) && validatePassword(passwordReset2)){

            const jwt = await GetTempJWT(useremailForget);
            const result = await ResetPasswordAPI(jwt,useremailForget,passwordReset1,codeReset);
            if (result.status === 'Reset successful'){
                alert(isCN?"重设密码成功！":"Password successfully reset!");
                setIsResetPasswordOpen(false);
                setSignUpOpen(false);setIsLoginOpen(false);setForgetPasswordOpen(false);
                handleLogin(useremailForget);
             }
             else{
                alert(isCN?"请检查验证码":"Invalid verification code.");
             }
        } else {
            alert(isCN?"无效的密码, 密码长度至少为8。":"Invalid password, please ensure that your password is more than 8 characters long.");
        }
    };

    return (
        <div>
             {showLoading && (
                  <div className="modal-overlay">
                    <div className="modal">
                      <div className="modal-content">
                            <br/>
                            <img src="loading.gif" width={60}/>
                      </div>

                        
                    </div>
                  </div>
                )}
            <ul>
            {/* login ****************************/}
            {isLoginOpen &&
                <div>
                    <div>
                        <h3>{isCN?"GPT Mate 登陆":"GPT Mate Login"}</h3>
                        <div>
                            <label>
                                <input
                                name="language"
                                type="radio"
                                value="EN"
                                checked={selectedOptionLang === 'EN'}
                                onChange={handleSelectLanguage}
                                />
                                English
                            </label> &nbsp;&nbsp;
                            <label>
                                <input
                                name="language"
                                type="radio"
                                value="CN"
                                checked={selectedOptionLang === 'CN'}
                                onChange={handleSelectLanguage}
                                />
                                中文
                            </label> &nbsp; &nbsp; &nbsp;
                            <button style = {{width: '100px',height:30,fontSize:16,backgroundColor:'white' ,color:'blue' ,border:0  }} onClick={()=>{setIsResetPasswordOpen(false);setSignUpOpen(true);setIsLoginOpen(false);setForgetPasswordOpen(false);}}>{isCN?"免费注册":"Sign Up"}</button>
                  
                        </div>
                        <br/>
                        <input
                            type="text" style = {{width: '250px',height:30,fontSize:16  }} maxLength={100}
                            placeholder={isCN?"电子邮箱":"Email"}
                            value={emailLogin}
                            onChange={(e) => setEmailLogin(e.target.value)}
                        />
                        <br /><br />
                        <input
                            type="password" style = {{width: '250px',height:30,fontSize:16  }} maxLength={50}
                            placeholder={isCN?"密码":"Password"}
                            value={passwordLogin}
                            onChange={(e) => setPasswordLogin(e.target.value)}
                        />
                        <br /><br />
                        <button onClick={login} style = {{width: '100px',height:30,fontSize:16  }}>{isCN?"登陆":"Login"}</button>
                        &nbsp; &nbsp; &nbsp;
                        <button style = {{width: '190px',height:30,fontSize:16,backgroundColor:'white',color:'blue',border:0 }} onClick={()=>{setIsResetPasswordOpen(false);setSignUpOpen(false);setIsLoginOpen(false);setForgetPasswordOpen(true);}}>{isCN?'忘记密码？':'forget password?'}</button>
<p>{isCN?'如登陆失败，请刷新网页试一下':'If login fails, please refresh the webpage and try again.'}</p>
                    </div>
                 
                      <Introduction/>
                   
                    <div>
                    <p style={{fontSize:10}}>Copyright © 2024 Smallsoft Pty Ltd. All rights reserved. <a href="https://shiol.com/contact" target="_blank" rel="noreferrer">{isCN?'联系我们':'Contact us'}</a></p>
    
                    <br/>
                    </div>
                </div>
            }

            {/* sign up ****************************/}
            {isSignUpOpen &&  
                <div style={{ height: '90vh', overflowY: 'auto'}}>
                    <h1>{isCN?"GPT新用户注册":"GPT Sign Up"}</h1>
                    <input
                        type="text" style = {{width: '180px',height:30,fontSize:16 }} maxLength={20}
                        placeholder={isCN?"用户名（长度至少为5）":"User name"}
                        value={usernameSignUp}
                        onChange={(e) => setUsernameSignUp(e.target.value)}
                    />
                    <br /><br />
                    <input style = {{width: '250px',height:30,fontSize:16 }} maxLength={100}
                        type="text"
                        placeholder={isCN?"电子邮箱":"Email"}
                        value={useremailSignUp}
                        onChange={(e) => setUseremailSignUp(e.target.value)}
                    />
                    <br /><br />
                     <input style = {{width: '250px',height:30,fontSize:16 }} maxLength={50}
                        type="password"
                        placeholder={isCN?"密码（长度至少为8）":"Password"}
                        value={passwordSignUp1}
                        onChange={(e) => setPasswordSignUp1(e.target.value)}
                    />
                    <br /><br />
                     <input style = {{width: '250px',height:30,fontSize:16 }} maxLength={50}
                        type="password"
                        placeholder={isCN?"确认密码（长度至少8）":"Confirm password"}
                        value={passwordSignUp2}
                        onChange={(e) => setPasswordSignUp2(e.target.value)}
                    />

                    <br /><br />
                    <input style = {{width: '60px',height:30,fontSize:16 }} maxLength={10}
                        type="text"
                        placeholder={isCN?"验证码":"code"}
                        value={codeSignUp}
                        onChange={(e) => setCodeSignUp(e.target.value)}
                    />&nbsp;
                    <button style = {{height:30,fontSize:16 }} onClick={()=>{SendCodeGetHash(useremailSignUp);}} disabled={btnSendCodeDisabled}>{isCN?"发送验证码到邮箱":"Email the code"}</button>&nbsp;&nbsp;
                   

                     {remainingTime != 0 && remainingTime}
                    <br /><br />
                    <button disabled={!agreeTerms} style = {{height:30,fontSize:16 }} onClick={signUp}>{isCN?"确定":"Sign Up"}</button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                <button style = {{height:30,fontSize:16 }} onClick={()=>{setIsResetPasswordOpen(false); setSignUpOpen(false);setIsLoginOpen(true);setForgetPasswordOpen(false);}}>{isCN?"取消":"Cancel"}</button>
                <br/><br/><br/><br/>
                <div>
          <input type="checkbox" id="agreeTerms" name="agreeTerms" checked={agreeTerms} onChange={handleCheckboxChange} required />
          <label htmlFor="agreeTerms"><a href="https://smallsoft.com.au/terms-and-conditions-for-the-gpt-mate-mobile-app/" target="_blank" rel="noreferrer">{isCN?'我同意《使用条款和条件》':'I agree to the Terms and Conditions'}</a></label>
        </div>
               
             
                
                </div>
            }

            {/* forget password ****************************/}
            {isForgetPasswordOpen && <div>
                <h1>{isCN?"发送验证码到邮箱":"Email the verification code"}</h1>
                    <input style = {{width: '250px',height:30,fontSize:16  }} maxLength={250}
                        type="text"
                        placeholder={isCN?"电子邮箱":"Email"}
                        value={useremailForget}
                        onChange={(e) => setUseremailForget(e.target.value)}
                    />
                    <br /><br />
                    <button style = {{width: '150px',height:30,fontSize:16  }} onClick={forgetPassword}>{isCN?"确定":"Send"}</button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button style = {{width: '80px',height:30,fontSize:16  }} onClick={()=>{setIsResetPasswordOpen(false); setSignUpOpen(false);setIsLoginOpen(true);setForgetPasswordOpen(false);}}>{isCN?"取消":"Cancel"}</button>
            </div>}

            {/* reset password ****************************/}
            {isResetPasswordOpen && <div>
                
                <h1>{isCN?"重设密码":"Reset password"}</h1>
                    <input style = {{width: '250px',height:30,fontSize:16  }} maxLength={250}
                         type="password"
                        placeholder={isCN?"密码":"password"}
                        value={passwordReset1}
                        onChange={(e) => setPasswordReset1(e.target.value)}
                    />
                    <br /><br />
                    <input style = {{width: '250px',height:30,fontSize:16  }} maxLength={250}
                         type="password"
                        placeholder={isCN?"确认密码":"Confirm password"}
                        value={passwordReset2}
                        onChange={(e) => setPasswordReset2(e.target.value)}
                    />
                    <br /><br />
                    <input style = {{width: '250px',height:30,fontSize:16  }} maxLength={20}
                        type="text"
                        placeholder={isCN?"验证码":"Verification code"}
                        value={codeReset}
                        onChange={(e) => setCodeReset(e.target.value)}
                    />
                    <br />
                    <label style={{fontSize:14}}>{isCN?'(验证码已发送到注册邮箱)':'(A verification code has been sent to your email address.)'}</label>
                    <br /><br />
                    <button style = {{width: '150px',height:30,fontSize:16  }} onClick={resetPassword}>{isCN?"确定":"Send"}</button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button style = {{width: '80px',height:30,fontSize:16  }} onClick={()=>{setIsResetPasswordOpen(false); setSignUpOpen(false);setIsLoginOpen(true);setForgetPasswordOpen(false);}}>{isCN?"取消":"Cancel"}</button>
                
                </div>}
            </ul>
        </div>
    );
};

export default Login;
