import React,{useState} from "react";
import { useGlobalState } from './StateContext';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Drawer, IconButton, List, ListItem, ListItemText } from '@mui/material';
import Chat from './chat.js'
import About from './about'
import Doc from './doc'
import Image from './image'
import Video from './video'
import Setting from './setting'
import Account from './account'
import Industry from './industry'

const Layout = () => {
    const {isCN,handleLogout } = useGlobalState();

    const logout = () => {
        handleLogout();
    };
    //#region menu
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };

    const [isChatOpen, setChatOpen] = useState(true);
    const [isDocOpen, setDocOpen] = useState(false);
    const [isImageOpen, setImageOpen] = useState(false);
    const [isVideoOpen, setVideoOpen] = useState(false);
    const [isSettingOpen, setSettingOpen] = useState(false);
    const [isAboutOpen, setAboutOpen] = useState(false);
    const [isAccountOpen, setAccountOpen] = useState(false);
    const [isIndustryOpen, setIndustryOpen] = useState(false);
    
    //#endregion

    return (

        <div>
            <div style={{ position: 'fixed', top:10}}>
              <IconButton onClick={toggleMenu}>
                  {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
              </IconButton>

              <Drawer anchor="left" open={isMenuOpen} onClose={toggleMenu}>
                  <List>
                      <ListItem onClick={()=>
                        {
                            setChatOpen(true);
                            setDocOpen(false);
                            setImageOpen(false);
                            setVideoOpen(false);
                            setSettingOpen(false);
                            setAboutOpen(false);
                            setAccountOpen(false);
                            setIndustryOpen(false);
                            toggleMenu();
                        }}>
                          <ListItemText primary={isCN?"AI聊天":"ChatGPT"} />
                      </ListItem>
                   {/*    <hr/>
                          <ListItem onClick={() => 
                        {
                            setChatOpen(false);
                            setDocOpen(true);
                            setImageOpen(false);
                            setVideoOpen(false);
                            setSettingOpen(false);
                            setAboutOpen(false);
                            setAccountOpen(false);
                            setIndustryOpen(true);
                            toggleMenu();
                        }}>
                          <ListItemText primary= {isCN?"教培AI":"Education AI"} />
                      </ListItem>
                 <hr/>
                 */}
                      <ListItem onClick={() => 
                        {
                            setChatOpen(false);
                            setDocOpen(true);
                            setImageOpen(false);
                            setVideoOpen(false);
                            setSettingOpen(false);
                            setAboutOpen(false);
                            setAccountOpen(false);
                            setIndustryOpen(false);
                            toggleMenu();
                        }}>
                          <ListItemText primary= {isCN?"文件处理":"Process Doc"} />
                      </ListItem>

                       <ListItem onClick={() => 
                        {
                            setChatOpen(false);
                            setDocOpen(false);
                            setImageOpen(true);
                            setVideoOpen(false);
                            setSettingOpen(false);
                            setAboutOpen(false);
                            setAccountOpen(false);
                            setIndustryOpen(false);
                            toggleMenu();
                        }}>
                          <ListItemText primary={isCN?"生成图片":"Generate Image"} />
                      </ListItem>

                    {/*      <ListItem onClick={() => 
                        {
                            setChatOpen(false);
                            setDocOpen(false);
                            setImageOpen(false);
                            setVideoOpen(true);
                            setSettingOpen(false);
                            setAboutOpen(false);
                            setAccountOpen(false);
                            setIndustryOpen(false);
                            toggleMenu();
                        }}>
                          <ListItemText primary={isCN?"生成视频":"Generate Video"} />
                      </ListItem> */}
                     
                      <ListItem onClick={() => 
                        {
                          setAccountOpen(true);
                            setChatOpen(false);
                            setDocOpen(false);
                            setImageOpen(false);
                            setVideoOpen(false);
                            setSettingOpen(false);
                            setAboutOpen(false);
                            setIndustryOpen(false);
                            toggleMenu();
                        }}>
                          <ListItemText primary={isCN?"我的帐户":"My Account"} />
                      </ListItem>

                      <ListItem onClick={() => 
                        {
                            setChatOpen(false);
                            setDocOpen(false);
                            setImageOpen(false);
                            setVideoOpen(false);
                            setSettingOpen(true);
                            setAboutOpen(false);
                            setAccountOpen(false);
                            setIndustryOpen(false);
                            toggleMenu();
                        }}>
                          <ListItemText primary={isCN?"设置":"Setting"} />
                      </ListItem>
                      <hr/>
                      <ListItem onClick={() => 
                        {
                            setChatOpen(false);
                            setDocOpen(false);
                            setImageOpen(false);
                            setVideoOpen(false);
                            setSettingOpen(false);
                            setAboutOpen(true);
                            setAccountOpen(false);
                            setIndustryOpen(false);
                            toggleMenu();
                        }}>
                          <ListItemText primary={isCN?"关于":"About"} />
                      </ListItem>

                      <ListItem onClick={logout}>
                          <ListItemText primary={isCN?"退出":"Logout"} />
                      </ListItem>
                  </List>
              </Drawer>
            </div>

            <div style={{ marginLeft: isMenuOpen ? 100 : 0 }}>
                {isChatOpen && (!isDocOpen && !isIndustryOpen && !isImageOpen && !isVideoOpen && !isAccountOpen && !isSettingOpen && !isAboutOpen) && <Chat/>}

                {isIndustryOpen && (!isChatOpen && isDocOpen && !isImageOpen && !isVideoOpen && !isAccountOpen && !isSettingOpen && !isAboutOpen) && <Industry/>}

                {isDocOpen && (!isChatOpen&& !isIndustryOpen && !isImageOpen && !isVideoOpen && !isAccountOpen && !isSettingOpen && !isAboutOpen) && <Doc/>}

                {isImageOpen && (!isChatOpen && !isIndustryOpen && !isDocOpen && !isVideoOpen && !isAccountOpen && !isSettingOpen && !isAboutOpen) && <Image/>}

                {isVideoOpen && (!isChatOpen && !isIndustryOpen && !isDocOpen && !isImageOpen && !isAccountOpen && !isSettingOpen && !isAboutOpen) && <Video/>}
  
                {isAccountOpen && (!isChatOpen && !isIndustryOpen && !isDocOpen && !isImageOpen && !isVideoOpen && !isSettingOpen && !isAboutOpen) && <Account/>}

                {isSettingOpen && (!isChatOpen && !isIndustryOpen && !isDocOpen && !isImageOpen && !isVideoOpen && !isAccountOpen && !isAboutOpen) && <Setting/>}

                {isAboutOpen && (!isChatOpen && !isIndustryOpen && !isDocOpen && !isImageOpen && !isVideoOpen && !isAccountOpen && !isSettingOpen) && <About/>}
                         
            </div>
      </div>
    );
};

export default Layout;